import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

const HomePage = React.lazy(() => import("pages/Home"));
const PrivacyPage = React.lazy(() => import("pages/Privacy"));
const PaymentPoliciesPage = React.lazy(() => import("pages/PaymentPolicies"));
const WarrantyPoliciesPage = React.lazy(() => import("pages/WarrantyPolicies"));
const ComplaintHandlingPoliciesPage = React.lazy(() => import("pages/ComplainHandlingPolicies"));
const CheckingPoliciesPage = React.lazy(() => import("pages/CheckingPolicies"));
const InformationPrivacyPoliciesPage = React.lazy(() => import("pages/InformationPrivacyPolicies"));

const MainRoutes = () => {
  return (
    <React.Suspense fallback={<span>Loading</span>}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/en/privacy" element={<PrivacyPage locale="en" />} />
        <Route path="/vi/privacy" element={<PrivacyPage locale="vi" />} />
        <Route path="/payment-policies" element={<PaymentPoliciesPage />} />
        <Route path="/warranty-policies" element={<WarrantyPoliciesPage />} />
        <Route path="/complaint-handling-policies" element={<ComplaintHandlingPoliciesPage />} />
        <Route path="/checking-policies" element={<CheckingPoliciesPage />} />
        <Route path="/information-privacy-policies" element={<InformationPrivacyPoliciesPage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </React.Suspense>
  );
};

export default MainRoutes;
