import './App.css'
import MainRoutes from "router/MainRoutes"

function App() {
  return (
    <MainRoutes/>
  )
}

export default App
